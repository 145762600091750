import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setSelectedProvider, setSelectedGU, setSelectedStudent } from '../../reducers/share';

export const GUSelect = props => {
    const { groupingUnits } = props;
    const dispatch = useDispatch();
    const selectedGU = useSelector(state => state.app.share.selectedGU);
    const history = useHistory();
    const location = useLocation();

    const handleChange = event => {
        const value = event.target.value;
        // We use location state when navigating from the Admin Dashboard to select the appropriate GU and Provider
        // Clearing the state here when we interact with the select field
        history.replace({ pathname: location.pathname, state: null });
        dispatch(setSelectedGU(value));
    };

    return (
        <label className="form-label">
            Select District
            <select value={selectedGU} onChange={handleChange} className="form-select">
                {groupingUnits.map(gu => (
                    <option key={gu.group.groupId} value={gu.group.groupId}>
                        {gu.group.label}
                    </option>
                ))}
            </select>
        </label>
    );
};

export const ProviderSelect = props => {
    const { providers } = props;
    const dispatch = useDispatch();
    const selectedProvider = useSelector(state => state.app.share.selectedProvider);
    const history = useHistory();
    const location = useLocation();

    const handleChange = event => {
        const value = event.target.value;
        history.replace({ pathname: location.pathname, state: null });
        dispatch(setSelectedProvider(value));
    };

    return (
        <label className="form-label">
            Select Provider
            <select value={selectedProvider} onChange={handleChange} className="form-select">
                {props.allProviders && <option value="all">All Providers</option>}
                {providers.map(provider => (
                    <option key={provider.pawn.pawnId} value={provider.pawn.pawnId}>
                        {provider.user.fullName} ({provider.user.username})
                    </option>
                ))}
            </select>
        </label>
    );
};

export const StudentSelect = props => {
    const { students } = props;
    const dispatch = useDispatch();
    const selectedStudent = useSelector(state => state.app.share.selectedStudent);

    const handleChange = event => {
        const value = event.target.value;
        dispatch(setSelectedStudent(value));
    };

    return (
        <label className="form-label">
            Select Student
            <select value={selectedStudent} onChange={handleChange} className="form-select">
                <option value="all">All Students</option>
                {students.map(student => (
                    <option key={student.pawn.pawnId} value={student.pawn.pawnId}>
                        {student.fields.firstName} {student.fields.lastName} (Provider:{' '}
                        {student.parentRoles.instructor.user.fullNameWithUsername})
                    </option>
                ))}
            </select>
        </label>
    );
};
