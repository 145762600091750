import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Results } from 'cccisd-laravel-assessment';
import PageBanner from '../../components/PageBanner';
import { ProviderSelect } from '../../components/Filters';
import { loadProviders, setSelectedProvider } from '../../reducers/share';

const Fortress = window.cccisd.fortress;
const actingRole = Fortress.user.acting.role.handle;

const ResultsPage = props => {
    const dispatch = useDispatch();
    const providers = useSelector(state => state.app.share.providers);
    const selectedProvider = useSelector(state => state.app.share.selectedProvider);

    useEffect(() => {
        const loadData = async () => {
            await dispatch(loadProviders({ guId: null, role: actingRole })).then(res => {
                const providerToSet = props.location.state?.provider || res.data.roles.instructorList[0].pawn.pawnId;
                dispatch(setSelectedProvider(providerToSet));
            });
        };

        if (['supervisor'].includes(actingRole)) {
            loadData();
        }
    }, []);

    return (
        <>
            <PageBanner
                title="Results"
                description="Select an assessment plan and student to start seeing and analyzing results"
            />
            {['supervisor'].includes(actingRole) && (
                <div style={{ display: 'flex', gap: '1em' }}>
                    <ProviderSelect providers={providers} />
                </div>
            )}
            {/* providerHandle is used for the Results query (supports instructor and orgInstructor handles)
                This should be updated in the future to support orgInstructor
            */}
            <Results
                selectedProvider={Number(selectedProvider)}
                providerHandle="instructor"
                planId={props.location.state?.assignmentPlanId}
            />
        </>
    );
};

export default ResultsPage;
