import React from 'react';
import style from './style.css';

const PageBanner = props => {
    const { title, description } = props;
    return (
        <div>
            <div className={style.panel}>
                <div className={style.panelLeft}>
                    <div className={style.panelHead}>
                        <div className={style.pageTitle}>{title || 'DASHBOARD'}</div>
                    </div>
                    <div className={style.panelBody}>
                        <div className={style.pageDescription}>
                            <span className={style.descriptionText}>{description}</span>
                        </div>
                    </div>
                </div>
                <div className={style.panelRight} />
            </div>
        </div>
    );
};

export default PageBanner;
