import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flags } from 'cccisd-laravel-assessment';
import Loader from 'cccisd-loader';
import PageBanner from '../../components/PageBanner';
import { ProviderSelect, GUSelect, StudentSelect } from '../../components/Filters';
import {
    loadProviders,
    loadGroupingUnits,
    setSelectedGU,
    setSelectedProvider,
    loadStudents,
    setSelectedStudent,
} from '../../reducers/share';
import _omit from 'lodash/omit';

const Fortress = window.cccisd.fortress;
// const actingPawnId = Fortress.user.acting.id;
// const providerHandle = Fortress.user.acting.role.handle;
const actingRole = Fortress.user.acting.role.handle;

const FlagsPage = props => {
    const [loading, setLoading] = useState(false);
    const [deploymentsInfo, setDeploymentsInfo] = useState({});
    const dispatch = useDispatch();
    const providers = useSelector(state => state.app.share.providers);
    const groupingUnits = useSelector(state => state.app.share.groupingUnits);
    const selectedProvider = useSelector(state => state.app.share.selectedProvider);
    const selectedGU = useSelector(state => state.app.share.selectedGU);
    const students = useSelector(state => state.app.share.students);
    const selectedStudent = useSelector(state => state.app.share.selectedStudent);

    useEffect(() => {
        if (
            actingRole === 'orgAdmin' &&
            groupingUnits.length > 0 &&
            providers.length > 0 &&
            !selectedGU &&
            !selectedProvider &&
            !props.location.state?.groupingUnit
        ) {
            dispatch(setSelectedGU(groupingUnits[0].group.groupId));
            dispatch(setSelectedProvider('all'));
        }
        // Clear select fields on unmount
        return () => {
            dispatch(setSelectedGU(null));
            dispatch(setSelectedProvider(null));
        };
    }, []);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await dispatch(loadGroupingUnits()).then(res => {
                const guToSet = props.location.state?.groupingUnit || res.data.groups.groupingUnitList[0].group.groupId;
                dispatch(setSelectedGU(guToSet));
            });
            setLoading(false);
        };

        if (actingRole === 'orgAdmin' && (groupingUnits.length < 1 || props.location.state?.groupingUnit)) {
            loadData();
        }
    }, []);

    useEffect(() => {
        const loadData = async () => {
            let guId = selectedGU;
            if (actingRole === 'guAdmin') {
                guId = Fortress.user.acting.group.id;
            }
            setLoading(true);
            await dispatch(loadProviders({ guId, role: actingRole })).then(res => {
                const providerToSet = props.location.state?.provider || 'all';
                dispatch(setSelectedProvider(providerToSet));
                const instructorList = res.data.roles.instructorList;
                setDeploymentsInfo(getDeploymentsInfo(instructorList, 'createdAssignmentPlanList'));
            });
            setLoading(false);
        };

        if (['guAdmin', 'supervisor'].includes(actingRole) || (actingRole === 'orgAdmin' && selectedGU)) {
            loadData();
        }
    }, [selectedGU]);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await dispatch(loadStudents()).then(res => {
                const studentsToSet = props.location.state?.student || 'all';
                dispatch(setSelectedStudent(studentsToSet));
                const learnerList = res.data.roles.learnerList;
                setDeploymentsInfo(getDeploymentsInfo(learnerList, 'selectedAssignmentPlanList'));
            });
            setLoading(false);
        };

        if (actingRole === 'siteAdmin') {
            loadData();
        }
    }, []);

    const getDeploymentsInfo = (userList, path) => {
        const info = {};
        userList.forEach(user => {
            user[path].forEach(plan => {
                plan.sessionList.forEach(session => {
                    if (session.sessionDeploymentInfo) {
                        for (const value of Object.values(session.sessionDeploymentInfo)) {
                            const pawnId = Number(value.pawnId);
                            const deploymentId = Number(value.deploymentId);
                            const sessionInfo = { deploymentId, pawnId, planLabel: plan.label, label: session.label };
                            if (!info[pawnId]) {
                                info[pawnId] = [deploymentId];
                            } else {
                                info[pawnId] = [...info[pawnId], deploymentId];
                            }
                            if (!info.planSessions) {
                                info.planSessions = [sessionInfo];
                            } else {
                                info.planSessions = [...info.planSessions, sessionInfo];
                            }
                        }
                    }
                });
            });
        });

        return info;
    };

    const getFlagsProps = deploymentData => {
        const selectedPawn = selectedStudent || selectedProvider;
        if (!selectedPawn) {
            return { pawnIds: null, deploymentIds: null };
        }
        deploymentData = _omit(deploymentData, 'planSessions');
        if (selectedPawn === 'all') {
            return {
                pawnIds: Object.keys(deploymentData).map(v => Number(v)),
                deploymentIds: Object.values(deploymentData)
                    .map(v => [...v])
                    .flat(),
            };
        }
        return {
            pawnIds: selectedStudent ? [Number(selectedStudent)] : null,
            deploymentIds: selectedStudent ? deploymentData[selectedStudent] : null,
        };
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <>
            <PageBanner
                title="Response Flags"
                description={
                    ['orgAdmin', 'guAdmin', 'supervisor', 'siteAdmin'].includes(actingRole)
                        ? 'Use the table to view flags, check their status, and view logs'
                        : 'Use the table to view flags, check their status, and take action to resolve flags, add notes, and view logs'
                }
            />
            {['orgAdmin', 'guAdmin', 'supervisor'].includes(actingRole) && (
                <div style={{ display: 'flex', gap: '1em' }}>
                    {actingRole === 'orgAdmin' && <GUSelect groupingUnits={groupingUnits} />}
                    <ProviderSelect allProviders providers={providers} guId={selectedGU} />
                </div>
            )}
            {actingRole === 'siteAdmin' && <StudentSelect students={students} />}
            <Flags
                selectedGU={selectedGU}
                selectedProvider={selectedProvider}
                selectedStudent={selectedStudent}
                deploymentIds={getFlagsProps(deploymentsInfo).deploymentIds}
                pawnIds={getFlagsProps(deploymentsInfo).pawnIds}
                planSessions={deploymentsInfo.planSessions}
            />
        </>
    );
};

export default FlagsPage;
