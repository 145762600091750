import { createAction, handleActions } from 'redux-actions';
import { client as apollo } from 'cccisd-apollo';

import providersQuery from '../queries/providers.graphql';
import supervisorProvidersQuery from '../queries/supervisorProviders.graphql';
import groupingUnitsQuery from '../queries/groupingUnits.graphql';
import learnersQuery from '../queries/learners.graphql';

export const initialState = {
    selectedGU: null, // i.e. District
    selectedProvider: null,
    providers: [],
    groupingUnits: [],
    students: [],
    selectedStudent: null,
};

// Actions
const SET_SELECTED_PROVIDER = 'app/share/SET_SELECTED_PROVIDER';
const SET_SELECTED_GU = 'app/share/SET_SELECTED_GU';
const SET_GROUPING_UNITS = 'app/share/SET_GROUPING_UNITS';
const SET_PROVIDERS = 'app/share/SET_PROVIDERS';
const SET_STUDENTS = 'app/share/SET_STUDENTS';
const SET_SELECTED_STUDENT = 'app/share/SET_SELECTED_STUDENT';

// Action Creators
export const setSelectedProvider = createAction(SET_SELECTED_PROVIDER);
export const setSelectedGU = createAction(SET_SELECTED_GU);
export const setProviders = createAction(SET_PROVIDERS);
export const setGroupingUnits = createAction(SET_GROUPING_UNITS);
export const setStudents = createAction(SET_STUDENTS);
export const setSelectedStudent = createAction(SET_SELECTED_STUDENT);

export const loadGroupingUnits = () => {
    return async (dispatch, getState) => {
        let result = null;
        try {
            result = await apollo.query({
                query: groupingUnitsQuery,
                variables: {},
                fetchPolicy: 'network-only',
            });
            // console.log(data);
            dispatch(setGroupingUnits(result.data.groups.groupingUnitList));
            // dispatch(setSelectedGU(result.data.groups.groupingUnitList[0].group.groupId));
            return result;
        } catch (e) {
            throw e;
        }
    };
};

export const loadProviders = ({ guId, role }) => {
    return async (dispatch, getState) => {
        let result = null;
        try {
            result = await apollo.query({
                query: role === 'supervisor' ? supervisorProvidersQuery : providersQuery,
                variables:
                    role === 'supervisor'
                        ? {}
                        : {
                              guId,
                          },
                fetchPolicy: 'network-only',
            });
            dispatch(setProviders(result.data.roles.instructorList));
            return result;
        } catch (e) {
            throw e;
        }
    };
};

export const loadStudents = () => {
    return async (dispatch, getState) => {
        let result = null;
        try {
            result = await apollo.query({
                query: learnersQuery,
                variables: {},
                fetchPolicy: 'network-only',
            });
            dispatch(setStudents(result.data.roles.learnerList));
            return result;
        } catch (e) {
            throw e;
        }
    };
};

// reducer
export default handleActions(
    {
        [SET_SELECTED_PROVIDER]: (state, action) => {
            return {
                ...state,
                selectedProvider: action.payload,
            };
        },
        [SET_PROVIDERS]: (state, action) => {
            return {
                ...state,
                providers: action.payload,
            };
        },
        [SET_GROUPING_UNITS]: (state, action) => {
            return {
                ...state,
                groupingUnits: action.payload,
            };
        },
        [SET_SELECTED_GU]: (state, action) => {
            return {
                ...state,
                selectedGU: action.payload,
            };
        },
        [SET_STUDENTS]: (state, action) => {
            return {
                ...state,
                students: action.payload,
            };
        },
        [SET_SELECTED_STUDENT]: (state, action) => {
            return {
                ...state,
                selectedStudent: action.payload,
            };
        },
    },
    initialState
);
